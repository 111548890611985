import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

const Whitepaper = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="whitepaper" lang="ja" />
        <title>{`カタログダウンロード | ${title}`}</title>
        <meta
          name="description"
          content="各製品のカタログ資料をダウンロードいただけます。"
        />
      </Helmet>

      <div className="common-header clearfix">
        <h1>カタログダウンロード</h1>
        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /<li>カタログダウンロード</li>
        </div>
      </div>

      <div className="main-wrapper whitepaper">
        <div className="row">
          <div className="container">
            <div className="title">ネットワーク対応精算機（PDF：707KB）</div>
            <div className="common-btn pc-only">
              <a
                href="/assets/files/whitepaper/itc-network.pdf"
                target="_blank"
              >
                カタログダウンロード
              </a>
            </div>
            <div className="common-btn sp-only">
              <a
                href="/assets/files/whitepaper/itc-network.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <i></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="title">WEB版遠隔監視システム（PDF：785KB）</div>
            <div className="common-btn pc-only">
              <a
                href="/assets/files/whitepaper/itc-webremote.pdf"
                target="_blank"
              >
                カタログダウンロード
              </a>
            </div>
            <div className="common-btn sp-only">
              <a
                href="/assets/files/whitepaper/itc-webremote.pdf"
                target="_blank"
              >
                <i></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="title">ナンバー認識システム（PDF：1,683KB）</div>
            <div className="common-btn pc-only">
              <a
                href="/assets/files/whitepaper/itc-numberrecognition.pdf"
                target="_blank"
              >
                カタログダウンロード
              </a>
            </div>
            <div className="common-btn sp-only">
              <a
                href="/assets/files/whitepaper/itc-numberrecognition.pdf"
                target="_blank"
              >
                <i></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="title">
              ゲート式駐車場管理システム（PDF：925KB）
            </div>
            <div className="common-btn pc-only">
              <a
                href="/assets/files/whitepaper/itc-gateparking.pdf"
                target="_blank"
              >
                カタログダウンロード
              </a>
            </div>
            <div className="common-btn sp-only">
              <a
                href="/assets/files/whitepaper/itc-gateparking.pdf"
                target="_blank"
              >
                <i></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="title">ロック板ユニット（PDF：684KB）</div>
            <div className="common-btn pc-only">
              <a
                href="/assets/files/whitepaper/itc-lockunit.pdf"
                target="_blank"
              >
                カタログダウンロード
              </a>
            </div>
            <div className="common-btn sp-only">
              <a
                href="/assets/files/whitepaper/itc-lockunit.pdf"
                target="_blank"
              >
                <i></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="title">防犯カメラシステム（PDF：3.5MB）</div>
            <div className="common-btn pc-only">
              <a href="/assets/files/whitepaper/itc-camera.pdf" target="_blank">
                カタログダウンロード
              </a>
            </div>
            <div className="common-btn sp-only">
              <a href="/assets/files/whitepaper/itc-camera.pdf" target="_blank">
                <i></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Whitepaper
